<template>
  <div>
    <div class="contain2">
      <div class="tableTool">
        <el-button type="primary" plain @click="onAddNewTemplate" size="mini"
        >添加</el-button
        >
      </div>
      <el-table
          :data="tableData"
          border
          height="calc(100vh - 230px)"
          stripe
          :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column
            label="序号"
            align="center"
            type="index"
        >
        </el-table-column>
        <el-table-column
            label="项目名称"
            align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="row.isUpdate==1">
              {{row.templateName}}
            </div>
            <div v-else>
              <el-input size="small" v-model="row.templateName" placeholder="请输入项目名称" show-word-limit></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="项目描述"
            align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="row.isUpdate==1">
              {{row.templateDescribe}}
            </div>
            <div v-else>
              <el-input size="small" v-model="row.templateDescribe" placeholder="请输入项目描述" show-word-limit></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="负责施工队"
            align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="row.isUpdate==1">
              <span v-if="row.constructionType==1">安装施工队</span>
              <span v-if="row.constructionType==2">土方施工队</span>
              <span v-if="row.constructionType==3">定向钻施工队</span>
            </div>
            <div v-else>
              <el-select size="small" v-model="row.constructionType" placeholder="请选择施工队">
                <el-option label="安装施工队" :value="1"></el-option>
                <el-option label="土方施工队" :value="2"></el-option>
                <el-option label="定向钻施工队" :value="3"></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="saveRow(scope.row)" v-if="scope.row.isUpdate!=1">保 存</el-button>
            <el-button type="danger" size="small"  @click="quxiao(scope.$index, tableData,scope.row)"  v-if="scope.row.isUpdate!=1">取 消</el-button>
            <el-button type="primary" size="small" @click="showSon(scope.row)" v-if="scope.row.isUpdate==1">操作子项</el-button>
            <el-button type="primary" size="small" v-if="scope.row.isUpdate==1" @click="modifyMode(scope.row)">编 辑</el-button>
            <el-button type="danger" size="small" @click="deleteRow(scope.$index, tableData,scope.row)" v-if="scope.row.isUpdate==1">移 除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="60%">
        <el-button type="primary" plain @click="twoAddNewTemplate" size="mini" style="margin-bottom: 10px">添加</el-button>
      <el-table
          :data="sonTableData"
          border
          stripe
          :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column
            label="序号"
            align="center"
            type="index"
        >
        </el-table-column>
        <el-table-column
            label="子项目名称"
            align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="row.isUpdate==1">
              {{row.templateSeedName}}
            </div>
            <div v-else>
              <el-input size="small" v-model="row.templateSeedName" placeholder="请输入内容" show-word-limit></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="类型"
            align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="row.isUpdate==1">
              <span v-if="row.templateSeedType==1">图片</span>
              <span v-if="row.templateSeedType==2">文本描述</span>
              <span v-if="row.templateSeedType==3">PDF</span>
            </div>
            <div v-else>
              <el-select size="small" v-model="row.templateSeedType" placeholder="请选择">
                <el-option label="图片" :value="1"></el-option>
                <el-option label="文本描述" :value="2"></el-option>
                <el-option label="PDF" :value="3"></el-option>
              </el-select>
            </div>

          </template>
        </el-table-column>
        <el-table-column
            label="必填项"
            align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="row.isUpdate==1">
              <span v-if="row.templateSeedRequired==1">是</span>
              <span v-if="row.templateSeedRequired==2">否</span>
            </div>
            <div v-else>
              <el-select size="small" v-model="row.templateSeedRequired" placeholder="请选择">
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="2"></el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="sonsaveRow(scope.row)" v-if="scope.row.isUpdate!=1">保 存</el-button>
            <el-button type="danger" size="small" @click="quxiao(scope.$index, sonTableData,scope.row)" v-if="scope.row.isUpdate!=1">取 消</el-button>
            <el-button type="primary" size="small" v-if="scope.row.isUpdate==1" @click="modifyModeSon(scope.row,scope.$index)">编 辑</el-button>
            <el-button type="danger" size="small" v-if="scope.row.isUpdate==1" @click="sondeleteRow(scope.$index, sonTableData,scope.row)">移 除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
    <el-button @click="closeItem">关 闭</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
  import {templateList,updateTemplate,deleteTemplate,saveTemplate,deleteSubitem,updateSubitem,saveSubitem,verifyList} from '@/RequestPort/earlier/index'
export default {
  data() {
    return {
      tableData: [], //  表格数据
      form:{},
      sonTableData:[],
      dialogVisible:false,
      pid:''
    };
  },
  mounted() {
    this.loadList()
  },
  methods: {
    closeItem(){
      this.dialogVisible = false
      this.loadList()
    },
    loadList(id){
      if(id){
        templateList({id:id}).then(res=>{
          res.data[0].templates.forEach(item=>{
            item.isUpdate = 1
          })
          this.sonTableData = res.data[0].templates
        })
      }else{
        templateList().then(res=>{
          this.tableData = res.data
        })
      }

    },
    modifyModeSon(rows,index){
      rows.isUpdate = 2
      let item={...rows}
      this.$set(this.sonTableData,index,item)
    },
    modifyMode(rows){
      rows.isUpdate = 2
      this.$forceUpdate()
    },
    quxiao(index, rows, row){
      if(row.id||row.seedId){
        row.isUpdate = 1
      }else{
        rows.splice(index, 1);
      }
    },
    deleteRow(index, rows, row) {
      if(row.id){
        this.$confirm('是否确认移除该项？', {
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type:'warning',
        }).then(() => {
          deleteTemplate({id:row.id}).then(res=>{
            if(res.code===200){
              this.$message.success(res.msg)
              this.loadList()
            }else {
              this.$message.error(res.msg)
            }
          })
        }).catch(()=>{})
      }else{
        rows.splice(index, 1);
      }
    },
    sondeleteRow(index, rows,row) {
      if(row.seedId){
        this.$confirm('是否确认移除该项？', {
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type:'warning',
        }).then(() => {
          deleteSubitem({id:row.seedId}).then(res=>{
            if(res.code===200){
              this.$message.success(res.msg)
              this.loadList(this.pid)
            }else {
              this.$message.error(res.msg)
            }
          })
        }).catch(()=>{})
      }else{
        rows.splice(index, 1);
      }
    },
    saveRow(data){
      if(data.id){
        if(data.templateDescribe==''){
          return this.$message.error('请完善项目描述')
        }
        if(data.templateName==''){
          return this.$message.error('请完善项目名称')
        }
        let obj = {
          constructionType: data.constructionType,
          id: data.id,
          templateDescribe: data.templateDescribe,
          templateName: data.templateName,
          templateType:1
        }
        updateTemplate(obj).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
            data.isUpdate = 1
          }
        })
      }else{
        if(data.templateDescribe==''){
          return this.$message.error('请完善项目描述')
        }
        if(data.templateName==''){
          return this.$message.error('请完善项目名称')
        }
        if(data.constructionType==''){
          return this.$message.error('请选择施工队类型')
        }
        data.templateType = 1
        saveTemplate(data).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
           this.loadList()
          }else {
             this.$message.error(res.msg)
          }
        })
      }

    },
    sonsaveRow(data){
      if(data.seedId){
        if(data.templateSeedName==''){
          return this.$message.error('请完善子项目名称')
        }
        let obj = {
          id: data.seedId,
          templateSeedRequired: data.templateSeedRequired,
          templateSeedName: data.templateSeedName,
          templateSeedType:data.templateSeedType
        }
        updateSubitem(obj).then(res=>{
          if(res.code===200){
            this.$message.success(res.msg)
            data.isUpdate = 1
            this.$forceUpdate()
          }
        })
      }else{
        if(data.templateSeedRequired==''){
          return this.$message.error('请完善必填项')
        }
        if(data.templateSeedName==''){
          return this.$message.error('请完善子项目名称')
        }
        if(data.templateSeedType==''){
          return this.$message.error('请选择类型')
        }
        data.pid = this.pid
        saveSubitem(data).then(res=>{
          if(res.code===200){
            data.isUpdate = 1
            this.loadList(this.pid)
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    onAddNewTemplate() {
      //这里点歌接口查一下，能不能添加，能添加在push,不能就直接弹窗
      verifyList({templateType:1,pid:''}).then(res=>{
        if(res.code===200){
          let arr =[]
          this.tableData.forEach(item=>{
            if(item.id){
              arr.push(1)
            }else {
              arr.push(2)
            }
          })
          if(arr.indexOf(2)==-1){
            this.tableData.push(
                {
                  templateDescribe:'',
                  templateName:'',
                  constructionType:''
                }
            )
          }else {
            this.$message.error('请完善信息后，再次添加！')
          }
        }else {
          // 弹窗
          this.$confirm(res.msg, {
            cancelButtonText: '关闭',
            closeOnClickModal:false,
            showConfirmButton:false,
            type:'warning',
          })
        }
      })


    },
    showSon(row){
      this.dialogVisible = !this.dialogVisible
      row.templates.forEach(item=>{
        item.isUpdate = 1
      })
      this.pid = row.id
      this.sonTableData = row.templates
    },
    twoAddNewTemplate() {
      let arr =[]
      this.sonTableData.forEach(item=>{
        if(item.seedId){
          arr.push(1)
        }else {
          arr.push(2)
        }
      })
      if(arr.indexOf(2)==-1){
        if(this.pid){
          this.sonTableData.push(
              {
                isUpdate:2,
                templateName:'',
                projectType:'',
                realname:''
              }
          )
        }else {
          this.$message.error('请点击要操作的一级模板')
        }
      }else {
        this.$message.error('请完善信息后，再次添加！')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contain2 {
  .tableTool {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
    .infoTitle {
      flex: 1;
      font-size: 18px;
    }
    .rightButtonTool {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}
.miniTitle {
  font-size: 16px;
  margin-bottom: 15px;
  color: #303133;
  &::before {
    content: "";
    display: inline-block;
    height: 16px;
    width: 3px;
    margin-right: 5px;
    vertical-align: middle;
    background-color: #1082ff;
  }
}
.form-select {
  width: 100%;
}
.isInUse {
  margin-left: 20px;
  color: #f00;
  text-decoration: underline;
}
</style>